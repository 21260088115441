import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Stack';

import { Label, ButtonIcon } from 'components/controls';

import CopyRights from 'components/copyrights';

const useStyles = makeStyles((theme) => ({
  root1: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
  root: {
    display: 'flex',
    height: '50px',
    // bottom: 0,
    // width: '100%',
  },
}));

const Footer = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  // return (
  //   <div className={clsx(classes.root, className)}>

  //     <CopyRights />

  //   </div>
  // );
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
      }}
    >
      <Container maxWidth="sm">
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <ButtonIcon
            iconName="Facebook"
            target="_blank"
            variant="contained"
            href="https://www.facebook.com/paarambariyauzhavan"
            tooltipText="Connect on Facebook"
          />
          <ButtonIcon
            iconName="Amazon"
            target="_blank"
            variant="contained"
            href="https://www.amazon.in/s?me=A11QFJZV2MRDY2&marketplaceID=A21TJRUUN4KGV"
            tooltipText="View our products in Amazon"
          />
          <ButtonIcon
            iconName="FlipKart"
            target="_blank"
            variant="contained"
            href="https://www.flipkart.com/food-products/pr?sid=eat&fm=neo%2Fmerchandising&iid=M_7c9d4081-4bf2-41e7-8ba1-dd7c1972348a_2_372UD5BXDFYS_MC.C86NE28MRZNW&otracker=hp_rich_navigation_1_2.navigationCard.RICH_NAVIGATION_Beauty%252C%2BToys%2B%2526%2BMore%7EFood%2B%2526%2BDrinks%7EView%2BAll_C86NE28MRZNW&otracker1=hp_rich_navigation_PINNED_neo%2Fmerchandising_NA_NAV_EXPANDABLE_navigationCard_cc_1_L2_view-all&cid=C86NE28MRZNW&p%5B%5D=facets.brand%255B%255D%3DCHOLAS"
            tooltipText="View our products in Flipkart"
          />
          <ButtonIcon
            iconName="Meesho"
            target="_blank"
            variant="contained"
            href="https://meesho.com/CHOLASTRADING"
            tooltipText="View our products in Meesho"
          />
        </Stack>

        <CopyRights />
      </Container>
    </Box>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
