import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  contact1: {
    // margin: theme.spacing(15),
    // left: theme.spacing(40),
    position: 'fixed',
  },
  title: {
    color: theme.palette.primary.main,
  },
}));
export default useStyles;
