import TextField from '@mui/material/TextField';

const TextBox = (props) => {
  const { name, label, error = null, value = '', onChange, ...rest } = props;
  return (
    <TextField
      margin="normal"
      id={name}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      {...(error && { error: true, helperText: error })}
      {...rest}
    />
  );
};
export default TextBox;

/*******************************
 * Example for import
 * <TextBox
            name="username"
            label={en.login.username}
            value={controlVal.username}
            onChange={onChange}
            error={controlErrorVal.username}
          />
 */
