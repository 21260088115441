import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize('G-RJVRTP59KL');
//ReactGA.initialize('315204827')

const GoogleAnalytics = ({ history }) => {
  // history.listen((location, action) => {
  //     ReactGA.set({ page: location.pathname });
  //     ReactGA.pageview(location.pathname);
  // });
  useEffect(() => {
    // ReactGA.set({ page: location.pathname });
    //  ReactGA.pageview(location.pathname);
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return <div></div>;
};

export default withRouter(GoogleAnalytics);
