import { useState, useEffect, useContext } from 'react';
// import { useHistory } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { Grid, Button, TextBox, DropDown } from 'components/controls';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import useControlEvent from 'utils/controlevent';
import Notification from 'components/notification';

import useStyles from './style';
import { cartContext } from 'shared/context/cart';
import { states } from './metadata';
import { confirmOrderService } from './service';

const ConfirmOrder = () => {
  // const history = useHistory();
  const classes = useStyles();
  const {
    controlVal,
    setControlVal,
    controlErrorVal,
    setControlErrorVal,
    onFieldValueChange,
  } = useControlEvent({});
  const [openNotification, setOpenNotification] = useState(false);
  const [notficationType, setNotificationType] = useState('success');
  const [notificationMessage, setNotificationMessage] = useState('No Message');
  const { cartItems, setCartItems } = useContext(cartContext);

  const submitConfirmOrder = async (e) => {
    e.preventDefault();
    const error = {};
    if (!cartItems.length) {
      setNotificationType('error');
      setNotificationMessage('Cart is empty. Please add to cart');
      setOpenNotification(true);
    }

    if (!controlVal.name) {
      error.name = 'Name required';
    }
    if (!controlVal.email) {
      error.email = 'Email Required';
    }
    if (!controlVal.state) {
      error.state = 'State Required';
    }
    if (!controlVal.contactno) {
      error.contactno = 'MobileNo Required';
    }

    if (!controlVal.pincode) {
      error.pincode = 'Pincode Required';
    }
    if (!controlVal.address) {
      error.address = 'Address Required';
    }
    setControlErrorVal(error);
    if (Object.keys(error).length) {
      return;
    }
    try {
      const params = {
        items: cartItems,
        details: controlVal,
      };

      const orderId = await confirmOrderService(params);
      if (orderId) {
        setNotificationType('success');
        setNotificationMessage(
          `Thanks for confirming the order.Your Order ID is ${orderId}. Our sales team will contact for the payment and shipping. Contact support with order No if any query`
        );
        setOpenNotification(true);
        setCartItems([]);
        setControlVal({});
      } else {
        setNotificationType('error');
        setNotificationMessage(
          'Something went wrong with order confirmation service. Please try again or contact support with the message'
        );
        setOpenNotification(true);
      }
    } catch (err) {
      console.log(err);
      setNotificationType('error');
      setNotificationMessage('Something went wrong. Please contact support');
      setOpenNotification(true);
    }

    //history.push('/products')
  };

  return (
    <Box className={classes.root}>
      <form noValidate onSubmit={submitConfirmOrder}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {cartItems.length > 0 && (
              <Stack direction="row" spacing={2}>
                <Typography variant="h6" component="div" noWrap>
                  No Of Items:
                  <Box component="span" fontWeight="bold">
                    {cartItems.length}
                  </Box>
                </Typography>
                <Typography variant="h6" component="div" noWrap>
                  Total Amount:
                  <Box component="span" fontWeight="bold">
                    ₹
                    {cartItems.reduce(
                      (prev, current) => prev + current.totalPrice,
                      0
                    )}
                  </Box>
                </Typography>
              </Stack>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider
              style={{
                border: '1px solid green',
                borderBottomWidth: 1,
                marginTop: 1,
                marginBottom: 10,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextBox
                name="name"
                label="Name"
                variant="standard"
                value={controlVal.name}
                onChange={onFieldValueChange}
                error={controlErrorVal.name}
              />
              <TextBox
                name="contactno"
                label="Mobile No"
                variant="standard"
                value={controlVal.contactno}
                onChange={onFieldValueChange}
                error={controlErrorVal.contactno}
              />
              <TextBox
                name="email"
                label="Email"
                variant="standard"
                value={controlVal.email}
                onChange={onFieldValueChange}
                error={controlErrorVal.email}
              />
            </Box>
            <Grid item xs={12}>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
              >
                <DropDown
                  name="state"
                  label="State"
                  value={controlVal.state}
                  onChange={onFieldValueChange}
                  options={states.map((state) => ({
                    label: state,
                    value: state,
                  }))}
                  error={controlErrorVal.state}
                />
                <TextBox
                  name="pincode"
                  label="Pincode"
                  variant="standard"
                  value={controlVal.pincode}
                  onChange={onFieldValueChange}
                  error={controlErrorVal.pincode}
                />
                <TextBox
                  name="address"
                  label="Address"
                  variant="standard"
                  value={controlVal.address}
                  onChange={onFieldValueChange}
                  error={controlErrorVal.address}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {cartItems.length > 0 && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                label="Confirm Order"
              />
            )}
          </Grid>
        </Grid>
        <Notification
          type={notficationType}
          message={notificationMessage}
          openNotification={openNotification}
          setOpenNotification={setOpenNotification}
        />
      </form>
    </Box>
  );
};
export default ConfirmOrder;
