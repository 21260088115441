import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import HomeLayout from 'layout/home';
import { UserSelector } from 'state/user';
import { AppSelector } from 'state/app';
import { APP_VERSION } from 'config/app.config';

const HomeRoute = ({ component: Component, location, roles, ...rest }) => {
  const token = useSelector(UserSelector.getUserToken);
  const userRoles = useSelector(UserSelector.getUserRoles);
  const userAppVersion = useSelector(AppSelector.getAppVersion);

  return (
    <Route
      {...rest}
      render={(props) => (
        <HomeLayout>
          <Component {...props} />
        </HomeLayout>
      )}
    />
  );
};
HomeRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
};

export default HomeRoute;
