import { useState } from 'react';

const useControlEvent = (initialState = {}) => {
  const [controlVal, setControlVal] = useState(initialState);
  const [controlErrorVal, setControlErrorVal] = useState({});

  const onFieldValueChange = (e) => {
    if (!e) {
      return;
    }
    let sdata = { ...controlVal };
    sdata[e.target.id || e.target.name] =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    // Reset error list if any one of field value is started to update
    if (Object.keys(controlErrorVal).length) {
      setControlErrorVal({});
    }
    setControlVal(sdata);
  };
  return {
    controlVal,
    setControlVal,
    controlErrorVal,
    setControlErrorVal,
    onFieldValueChange,
  };
};

export default useControlEvent;
