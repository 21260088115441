import Typography from '@mui/material/Typography';
import MuiIconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { ButtonIcon, Button, Grid } from 'components/controls';
import { en } from 'config/metadata/en';
import { IconList } from 'icons';

import useStyles from './style';
import CartBar from './cart';

const TopToolBar = () => {
  const classes = useStyles();

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  return (
    <Toolbar variant="dense">
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>

      <div className={classes.grow} />
      <CartBar />
      <div className={classes.sectionDesktop}>
        <Breadcrumbs color="inherit" aria-label="breadcrumb">
          <Link
            component="button"
            variant="body2"
            color="inherit"
            onClick={() => {
              console.log('Log in');
            }}
          >
            Login
          </Link>
          <Link
            component="button"
            variant="body2"
            color="inherit"
            onClick={() => {
              console.log('New user');
            }}
            noWrap
          >
            New User
          </Link>
        </Breadcrumbs>
      </div>
      <div className={classes.sectionMobile}>
        <ButtonIcon
          iconName="More"
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit"
        />
      </div>
    </Toolbar>
  );
};

export default TopToolBar;
/*
    <ButtonIcon
          iconName="ShoppingCart"
          aria-label="ShoppingCart"
          onClick={() => {
            console.info('Add to cart');
          }}
          color="inherit"
        />
        */
