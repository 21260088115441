import './App.css';
import GlobalThemeprovider from './theme/themeprovider';
import Router from './route';
import { Provider } from 'react-redux';
import configureStore from './state/configurestore';
import CartProvider from 'shared/context/cart';

function App() {
  const store = configureStore();
  return (
    <Provider store={store}>
      <CartProvider>
        <GlobalThemeprovider>
          <Router />
        </GlobalThemeprovider>
      </CartProvider>
    </Provider>
  );
}

export default App;
