import { useState, useContext } from 'react';
import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

import { Grid, Button } from 'components/controls';
import { IconList } from 'icons';
import { cartContext } from 'shared/context/cart';

const CheckoutItem = ({
  id,
  title,
  thumbnailUrl,
  price,
  sellingPrice,
  quantity,
  unit,
  totalQuantity,
  totalPrice,
}) => {
  const { cartItems, setCartItems } = useContext(cartContext);

  const deleteCheckout = (id) => {
    const newCartItems = [];
    for (const item of cartItems) {
      if (item.id !== id) {
        newCartItems.push(item);
      }
    }

    setCartItems(newCartItems);
  };

  return (
    <Paper
      sx={{
        p: 1,
        margin: 'auto',
        width: 500,
        borderRadius: '3%',
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        '&:hover': {
          background: '#f0f0f1',
        },
      }}
    >
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <Box
            component="img"
            sx={{
              height: 50,
              width: 50,
              display: 'block',
              //maxWidth: 300,
              overflow: 'hidden',
              //width: '100%',
            }}
            src={thumbnailUrl}
            alt={title}
          />
        </Grid>

        <Grid item xs={10}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography
                sx={{ fontWeight: 'bold' }}
                variant="subtitle2"
                component="div"
              >
                {title}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="subtitle1" component="div">
                Quantity:
                <Box component="span" fontWeight="bold">
                  {totalQuantity}
                </Box>
                {'    '}Amount:
                <Box component="span" fontWeight="bold">
                  ₹{totalPrice}
                </Box>
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              label="Delete"
              startIcon={IconList.Delete}
              //className={classes.BtnSearch}
              onClick={() => deleteCheckout(id)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CheckoutItem;
