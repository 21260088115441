import { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import useControlEvent from 'utils/controlevent';
import { DropDown, Button } from 'components/controls';
import { IconList } from 'icons';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ButtonBase from '@mui/material/ButtonBase';

const GalleryList = ({ gallery, setThumbnailUrl }) => {
  const galleryClick = (imageUrl) => {
    setThumbnailUrl(imageUrl);
  };
  return (
    <ImageList sx={{ width: 100, height: 250 }} cols={1} rowHeight="auto">
      {gallery.map((item) => (
        <ButtonBase key={item.name} onClick={() => galleryClick(item.url)}>
          <ImageListItem key={item.name} cols={1} rows={1}>
            <img src={item.url} alt={item.title} loading="lazy" />
          </ImageListItem>
        </ButtonBase>
      ))}
    </ImageList>
  );
};

const ProductGallery = ({ product }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState(null);

  useEffect(() => {
    setThumbnailUrl(product.thumbnailUrl);
  }, [product.thumbnailUrl]);

  return (
    <Stack
      direction="row"
      // spacing={{ xs: 1, sm: 2, md: 4 }}
    >
      {' '}
      <Box
        component="img"
        sx={{
          height: 250,
          width: 250,
          display: 'block',
          //maxWidth: 300,
          overflow: 'hidden',
          //width: '100%',
        }}
        src={thumbnailUrl}
        alt={product.title}
      />
      <GalleryList
        gallery={product.gallery || []}
        setThumbnailUrl={setThumbnailUrl}
      />
    </Stack>
  );
};

export default ProductGallery;
