import SvgIcon from '@mui/material/SvgIcon';

import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ListIcon from '@mui/icons-material/List';
import PageviewIcon from '@mui/icons-material/Pageview';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ReportIcon from '@mui/icons-material/Report';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SendIcon from '@mui/icons-material/Send';
import CommentIcon from '@mui/icons-material/Comment';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import GetAppIcon from '@mui/icons-material/GetApp';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import EmailIcon from '@mui/icons-material/Email';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalMallIcon from '@mui/icons-material/LocalMall';

import { ReactComponent as FacebookIcon } from './custom/facebook.svg';
import { ReactComponent as FlipKartIcon } from './custom/flipkart.svg';
import { ReactComponent as AmazonIcon } from './custom/amazon.svg';
import { ReactComponent as MeeshoIcon } from './custom/meesho.svg';

export const IconList = {
  Default: <ReportIcon />,
  Home: <HomeIcon />,
  Inbox: <InboxIcon />,
  Mail: <MailIcon />,
  Dashboard: <DashboardIcon />,
  Search: <PageviewIcon />,
  Admin: <SupervisorAccountIcon />,
  FileUpload: <CloudUploadIcon />,
  FileInsert: <InsertDriveFileIcon />,
  FileAttach: <AttachFileIcon />,
  Report: <ListIcon />,
  Exit: <ExitToAppIcon />,
  Member: <PermPhoneMsgIcon />,
  Edit: <EditIcon />,
  Delete: <DeleteIcon />,
  View: <VisibilityIcon />,
  Close: <CloseIcon />,
  Filter: <FilterListIcon />,
  DownArrow: <KeyboardArrowDownIcon />,
  UpArrow: <KeyboardArrowUpIcon />,
  Send: <SendIcon />,
  Comment: <CommentIcon />,
  Assessment: <AssessmentIcon />,
  Check: <CheckIcon />,
  CheckCircle: <CheckCircleIcon />,
  CheckList: <PlaylistAddCheckIcon />,
  ImportExport: <ImportExportIcon />,
  Download: <GetAppIcon />,
  HandTouch: <TouchAppIcon />,
  Email: <EmailIcon />,
  Hospital: <LocalHospitalIcon />,
  Info: <InfoIcon />,
  User: <AccountCircleIcon />,
  Menu: <MenuIcon />,
  Notification: <NotificationsIcon />,
  More: <MoreIcon />,
  Receipt: <ReceiptIcon />,
  Swap: <SwapHorizIcon />,
  Submission: <DoubleArrowIcon />,
  ShoppingCart: <ShoppingCartIcon />,
  Buy: <LocalMallIcon />,

  Facebook: (
    <SvgIcon>
      {' '}
      <FacebookIcon />
    </SvgIcon>
  ),
  FlipKart: (
    <SvgIcon>
      {' '}
      <FlipKartIcon />
    </SvgIcon>
  ),
  Amazon: (
    <SvgIcon>
      {' '}
      <AmazonIcon />
    </SvgIcon>
  ),
  Meesho: (
    <SvgIcon>
      {' '}
      <MeeshoIcon />
    </SvgIcon>
  ),
};
