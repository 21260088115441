export const productList = [
  {
    id: 'RICE-KARUPPUKAVUNI',
    category: 'rice',
    title: 'Karuppu Kavuni Rice / Black Rice / கருப்பு கவுனி',
    thumbnailUrl:
      'https://puzhavan.com/passets/images/product/RICE-KARUPPUKAVUNI/main.jpg',
    price: 300,
    sellingPrice: 230,
    quantity: 1,
    unit: 'kg',
    gallery: [
      {
        title: 'main',
        url: 'https://puzhavan.com/passets/images/product/RICE-KARUPPUKAVUNI/main.jpg',
      },
      {
        title: 'cover',
        url: 'https://puzhavan.com/passets/images/product/RICE-KARUPPUKAVUNI/cover.jpg',
      },
      {
        title: 'Other-1',
        url: 'https://puzhavan.com/passets/images/product/RICE-KARUPPUKAVUNI/other-1.jpg',
      },
      {
        title: 'Other-2',
        url: 'https://puzhavan.com/passets/images/product/RICE-KARUPPUKAVUNI/other-2.png',
      },
      {
        title: 'Back Over',
        url: 'https://puzhavan.com/passets/images/product/RICE-KARUPPUKAVUNI/back-cover.jpg',
      },
    ],
  },
  {
    id: 'RICE-SEERAGASAMBA',
    category: 'rice',
    title: 'Seeraga Samba Rice / Jeera Rice / வாசனை சீரகச் சம்பா',
    thumbnailUrl:
      'https://puzhavan.com/passets/images/product/RICE-SEERAGASAMBA/main.png',
    price: 200,
    sellingPrice: 120,
    quantity: 1,
    unit: 'kg',
    gallery: [
      {
        title: 'main',
        url: 'https://puzhavan.com/passets/images/product/RICE-SEERAGASAMBA/main.png',
      },
      {
        title: 'Front Cover',
        url: 'https://puzhavan.com/passets/images/product/RICE-SEERAGASAMBA/front-cover.jpg',
      },
      {
        title: 'Other',
        url: 'https://puzhavan.com/passets/images/product/RICE-SEERAGASAMBA/other-1.png',
      },
      {
        title: 'Other',
        url: 'https://puzhavan.com/passets/images/product/RICE-SEERAGASAMBA/other-2.png',
      },
      {
        title: 'Other',
        url: 'https://puzhavan.com/passets/images/product/RICE-SEERAGASAMBA/other-3.jpeg',
      },
      {
        title: 'Back Cover',
        url: 'https://puzhavan.com/passets/images/product/RICE-SEERAGASAMBA/back-cover.jpg',
      },
    ],
  },
  {
    id: 'RICE-NAVARA',
    category: 'rice',
    title: 'Navara Rice/ Ayurvedic Njavara Rice/ നവര അരി/ நவரா',
    thumbnailUrl: 'https://m.media-amazon.com/images/I/41Kzkwym2BL.jpg',
    price: 150,
    sellingPrice: 100,
    quantity: 1,
    unit: 'kg',
    gallery: [
      {
        title: 'main',
        url: 'https://m.media-amazon.com/images/I/41Kzkwym2BL.jpg',
      },

      {
        title: 'Other 1',
        url: 'https://m.media-amazon.com/images/I/412yqiIESBL.jpg',
      },
      {
        title: 'Other 2',
        url: 'https://m.media-amazon.com/images/I/31vsujxlPTL.jpg',
      },
      {
        title: 'Other 3',
        url: 'https://m.media-amazon.com/images/I/41p5Q9PQ3xL.jpg',
      },
    ],
  },

  {
    id: 'RICE-MAPILLAISAMBA',
    category: 'rice',
    title: 'Mappillai Samba Rice/ மாப்பிள்ளைச் சம்பா',
    thumbnailUrl:
      'https://m.media-amazon.com/images/I/61JfsY85MxL._SL1500_.jpg',
    price: 200,
    sellingPrice: 90,
    quantity: 1,
    unit: 'kg',
  },
  {
    id: 'RICE-POONGAR',
    category: 'rice',
    title: 'Poongar Rice/ Red Rice/பூங்கார்',
    thumbnailUrl:
      'https://m.media-amazon.com/images/I/61AjyU3VFTL._SL1500_.jpg',
    price: 200,
    sellingPrice: 90,
    quantity: 1,
    unit: 'kg',
  },
  {
    id: 'RICE-MOONGIL',
    category: 'rice',
    title: 'Bamboo Rice/ Moongil Rice/ மூங்கில் அரிசி',
    thumbnailUrl:
      'https://m.media-amazon.com/images/I/81rOOFo-ZqL._SL1500_.jpg',
    price: 800,
    sellingPrice: 600,
    quantity: 1,
    unit: 'kg',
  },
  {
    id: 'RICE-KATTUYANAM',
    category: 'rice',
    title: 'Kaattu Yanam Rice/ காட்டுயானம்',
    thumbnailUrl:
      'https://m.media-amazon.com/images/I/51z3aGUKfnL._SL1175_.jpg',
    price: 200,
    sellingPrice: 100,
    quantity: 1,
    unit: 'kg',
    gallery: [
      {
        title: 'main',
        url: 'https://m.media-amazon.com/images/I/61O134CDrXL._SL1500_.jpg',
      },
      {
        title: 'Other',
        url: 'https://m.media-amazon.com/images/I/51z3aGUKfnL._SL1175_.jpg',
      },
      {
        title: 'Other',
        url: 'https://m.media-amazon.com/images/I/51MiIA5bkML._SL1175_.jpg',
      },
      {
        title: 'Other',
        url: 'https://m.media-amazon.com/images/I/61cXatunG9L._SL1175_.jpg',
      },
    ],
  },
  {
    id: 'RICE-KARUNGURUVAI',
    category: 'rice',
    title: 'Karunguruvai Rice/ கருங்குறுவை',
    thumbnailUrl:
      'https://m.media-amazon.com/images/I/61vQapNNOuL._SL1500_.jpg',
    price: 200,
    sellingPrice: 90,
    quantity: 1,
    unit: 'kg',
  },
  {
    id: 'RICE-KULLAKAR',
    category: 'rice',
    title: 'Kullakar Rice/ குள்ளக்கார்',
    thumbnailUrl:
      'https://m.media-amazon.com/images/I/61cEiEbEyML._SL1500_.jpg',
    price: 200,
    sellingPrice: 100,
    quantity: 1,
    unit: 'kg',
  },
  {
    id: 'RICE-ARUVATHAMKURUVAI',
    category: 'rice',
    title: 'Aruvatham Kuruvai Rice/ அறுவதாம்குருவை அரிசி',
    thumbnailUrl:
      'https://m.media-amazon.com/images/I/71v3+q2kqEL._SL1500_.jpg',
    price: 200,
    sellingPrice: 100,
    quantity: 1,
    unit: 'kg',
    gallery: [
      {
        title: 'main',
        url: 'https://m.media-amazon.com/images/I/61AjyU3VFTL._SL1500_.jpg',
      },
      {
        title: 'Other',
        url: 'https://m.media-amazon.com/images/I/71v3+q2kqEL._SL1500_.jpg',
      },
      {
        title: 'Other',
        url: 'https://m.media-amazon.com/images/I/81q8jVxAqwL._SL1500_.jpg',
      },
      {
        title: 'Other',
        url: 'https://m.media-amazon.com/images/I/81zMajWdibL._SL1500_.jpg',
      },
    ],
  },

  {
    id: 'RICE-ATTUTKICHILISAMBA',
    category: 'rice',
    title: 'Attur Kichili Samba / ஆத்தூர் கிச்சலி சம்பா',
    thumbnailUrl: 'https://m.media-amazon.com/images/I/416K89W5DIL.jpg',
    price: 200,
    sellingPrice: 90,
    quantity: 1,
    unit: 'kg',
    gallery: [
      {
        title: 'main',
        url: 'https://m.media-amazon.com/images/I/416K89W5DIL.jpg',
      },

      {
        title: 'Other 1',
        url: 'https://m.media-amazon.com/images/I/31U2GdyZoCL.jpg',
      },
      {
        title: 'Other 2',
        url: 'https://m.media-amazon.com/images/I/31mhvoawtzL.jpg',
      },
      {
        title: 'Other 3',
        url: 'https://m.media-amazon.com/images/I/41esiJogcUL.jpg',
      },
    ],
  },
  {
    id: 'RICE-THOOYAMALLI',
    category: 'rice',
    title: 'Thooyamalli Rice / Jasmine Rice / தூயமல்லி அரிசி',
    thumbnailUrl: 'https://m.media-amazon.com/images/I/41lr+XlQOPL.jpg',
    price: 150,
    sellingPrice: 85,
    quantity: 1,
    unit: 'kg',
    gallery: [
      {
        title: 'main',
        url: 'https://m.media-amazon.com/images/I/41lr+XlQOPL.jpg',
      },

      {
        title: 'Other 1',
        url: 'https://m.media-amazon.com/images/I/312ZCfEZrCL.jpg',
      },
      {
        title: 'Other 2',
        url: 'https://m.media-amazon.com/images/I/31RkxDwGz4L.jpg',
      },
      {
        title: 'Other 3',
        url: 'https://m.media-amazon.com/images/I/31X2pLibgKL.jpg',
      },
    ],
  },
  {
    id: 'RICE-RATHASALI',
    category: 'rice',
    title: 'Rathasali Rice / இரத்தசாலி அரிசி',
    thumbnailUrl: 'https://m.media-amazon.com/images/I/61Bx-soiYKL._SY741_PIbundle-5,TopRight,0,0_SX465SY741SH20_.jpg',
    price: 150,
    sellingPrice: 120,
    quantity: 1,
    unit: 'kg',
    gallery: [
      {
        title: 'main',
        url: 'https://m.media-amazon.com/images/I/61Bx-soiYKL._SY741_PIbundle-5,TopRight,0,0_SX465SY741SH20_.jpg',
      },

      {
        title: 'Other 1',
        url: 'https://m.media-amazon.com/images/I/81je3zcCdvL._SX679_.jpg',
      },
      {
        title: 'Other 2',
        url: 'https://m.media-amazon.com/images/I/81L4UMKvDSL._SX679_.jpg',
      }
    ],
  },

  {
    id: 'AVAL-RED',
    category: 'aval',
    title: 'Red Rice Aval/Red Poha/சிவப்பு அரிசி அவல்',
    thumbnailUrl:
      'https://m.media-amazon.com/images/I/81OgZhZj25L._SL1500_.jpg',
    price: 200,
    sellingPrice: 110,
    quantity: 1,
    unit: 'kg',
    gallery: [
      {
        title: 'main',
        url: 'https://m.media-amazon.com/images/I/61a4ZA-DM9L._SL1500_.jpg',
      },
      {
        title: 'Other',
        url: 'https://m.media-amazon.com/images/I/81OgZhZj25L._SL1500_.jpg',
      },
    ],
  },
  {
    id: 'AVAL-Black',
    category: 'aval',
    title: 'Black Rice poha / Karuppu Kavuni AVAL / கருப்பு கவுனி அவல்',
    thumbnailUrl:
      'https://m.media-amazon.com/images/I/815jOkGVRhL._SL1500_.jpg',
    price: 300,
    sellingPrice: 170,
    quantity: 1,
    unit: 'kg',
    gallery: [
      {
        title: 'main',
        url: 'https://m.media-amazon.com/images/I/61+at4c2BHL._SL1500_.jpg',
      },
      {
        title: 'Other',
        url: 'https://m.media-amazon.com/images/I/81Y-9vpM9lL._SL1500_.jpg',
      },
      {
        title: 'Other',
        url: 'https://m.media-amazon.com/images/I/81i4avmvPHL._SL1500_.jpg',
      },
      {
        title: 'Other',
        url: 'https://m.media-amazon.com/images/I/815jOkGVRhL._SL1500_.jpg',
      },
    ],
  },
  {
    id: 'MILLET-THINAI  ',
    category: 'millet',
    title:
      ' Foxtail Millet/ Thinai Rice/ தினை அரிசி / फॉक्‍सटेल मिलेट / మిల్లెట్ /',
    thumbnailUrl: 'https://m.media-amazon.com/images/I/41Hn6p8FZOL.jpg',
    price: 200,
    sellingPrice: 100,
    quantity: 1,
    unit: 'kg',
    gallery: [
      {
        title: 'main',
        url: 'https://m.media-amazon.com/images/I/41c1XVVB5xL.jpg',
      },
      {
        title: 'Other-1',
        url: 'https://m.media-amazon.com/images/I/41Hn6p8FZOL.jpg',
      },
      {
        title: 'Other-2',
        url: 'https://m.media-amazon.com/images/I/41b0869HNvL.jpg',
      },
      {
        title: 'Other-3',
        url: 'https://m.media-amazon.com/images/I/41fhTAiBI0L.jpg',
      },
    ],
  },
  {
    id: 'MILLET-VARAGU  ',
    category: 'millet',
    title:
      ' Kodo Millet / Varagu Arisi / வரகு அரிசி /  కోడో మిల్లెట్ / കൊഡോ മില്ലറ്റ് / ಕೊಡೋ ರಾಗಿ  /  कोदो बाजरा  ',
    thumbnailUrl: 'https://m.media-amazon.com/images/I/41R2PAlb39L.jpg',
    price: 200,
    sellingPrice: 100,
    quantity: 1,
    unit: 'kg',
    gallery: [
      {
        title: 'main',
        url: 'https://m.media-amazon.com/images/I/41rjaMS+YxL.jpg',
      },
      {
        title: 'Other-1',
        url: 'https://m.media-amazon.com/images/I/41hf0Eu11kL.jpg',
      },
      {
        title: 'Other-2',
        url: 'https://m.media-amazon.com/images/I/41h+Uuu+PeL.jpg',
      },
      {
        title: 'Other-3',
        url: 'https://m.media-amazon.com/images/I/41R2PAlb39L.jpg',
      },
    ],
  },
  {
    id: 'MILLET-LITTLE',
    category: 'millet',
    title:
      'Little Millet / Samai arisi / சாமை / Panicum sumatrense / సామలు / थोड़ा बाजरा /  ಸ್ವಲ್ಪ ರಾಗಿ / ചെറിയ തിന ',
    thumbnailUrl: 'https://m.media-amazon.com/images/I/41cKQfeZc5L.jpg',
    price: 200,
    sellingPrice: 110,
    quantity: 1,
    unit: 'kg',
    gallery: [
      {
        title: 'main',
        url: 'https://m.media-amazon.com/images/I/41h2fgG0M2L.jpg',
      },
      {
        title: 'cover',
        url: 'https://m.media-amazon.com/images/I/41cKQfeZc5L.jpg',
      },
      {
        title: 'Other-1',
        url: 'https://m.media-amazon.com/images/I/41cKQfeZc5L.jpg',
      },
      {
        title: 'Other-2',
        url: 'https://m.media-amazon.com/images/I/31879hGbGCL.jpg',
      },
      {
        title: 'Other-3',
        url: 'https://m.media-amazon.com/images/I/41KEALsHaxL.jpg',
      },
    ],
  },
  {
    id: 'MILLET-BARNYARD',
    category: 'millet',
    title:
      'Barnyard Millet / Kuthiravali / குதிரைவாலி  / బార్న్యార్డ్ మిల్లెట్ / बरनार्ड बाजरा / ಬಾರ್ನ್ಯಾರ್ಡ್ ರಾಗಿ / ബർനിയാർഡ് മില്ലറ്റ് ',
    thumbnailUrl: 'https://m.media-amazon.com/images/I/41mJFffggJL.jpg',
    price: 200,
    sellingPrice: 120,
    quantity: 1,
    unit: 'kg',
    gallery: [
      {
        title: 'main',
        url: 'https://m.media-amazon.com/images/I/41ZOpr+b9iL.jpg',
      },
      {
        title: 'cover',
        url: 'https://m.media-amazon.com/images/I/41ZOpr+b9iL.jpg',
      },
      {
        title: 'Other-1',
        url: 'https://m.media-amazon.com/images/I/41V4Kdfi5kL.jpg',
      },
      {
        title: 'Other-2',
        url: 'https://m.media-amazon.com/images/I/41mJFffggJL.jpg',
      },
      {
        title: 'Other-3',
        url: 'https://m.media-amazon.com/images/I/41espxl6l6L.jpg',
      },
    ],
  },
  {
    id: 'JAGGERY',
    category: 'jaggery',
    title: 'Jaggery Powder / Nattu Sakkarai / நாட்டு சர்க்கரை',
    thumbnailUrl:
      'https://m.media-amazon.com/images/I/61rWiwE5cZL._SL1500_.jpg',
    price: 150,
    sellingPrice: 110,
    quantity: 1,
    unit: 'kg',
  },
  {
    id: ' OIL-SESAME',
    category: 'oil',
    title: ' Wood pressed Sesame Oil / மரச்செக்கு நல்லெண்ணெய்  /  तिल का तेल  ',
    thumbnailUrl: 'https://m.media-amazon.com/images/I/31JRltoBVOL.jpg',
    price: 500,
    sellingPrice: 430,
    quantity: 1,
    unit: 'L',
    gallery: [
      {
        title: 'main',
        url: 'https://m.media-amazon.com/images/I/31JRltoBVOL.jpg',
      },
      {
        title: 'Other-1',
        url: 'https://m.media-amazon.com/images/I/31NI+vJ8JsL.jpg',
      },
      {
        title: 'Other-2',
        url: 'https://m.media-amazon.com/images/I/41rkBLDgJLL.jpg',
      },
    ],
  },
  {
    id: ' OIL-GROUNDNUT ',
    category: 'oil',
    title:
      ' Wood Pressed Groundnut oil / Wood Pressed Peanut oil / மரச்செக்கு நிலக்கடலை எண்ணெய் / मूँगफली का तेल  / Arachis hypogaea  ',
    thumbnailUrl: 'https://m.media-amazon.com/images/I/31W-YHm7c4L.jpg',
    price: 300,
    sellingPrice: 260,
    quantity: 1,
    unit: 'L',
    gallery: [
      {
        title: 'main',
        url: 'https://m.media-amazon.com/images/I/31W-YHm7c4L.jpg',
      },
      {
        title: 'Other-1',
        url: 'https://m.media-amazon.com/images/I/413pes6Vl3L.jpg',
      },
      {
        title: 'Other-2',
        url: 'https://m.media-amazon.com/images/I/31bL8ldD-ML.jpg',
      },

      {
        title: 'Other-3',
        url: 'https://m.media-amazon.com/images/I/41e4CHY6VtL.jpg',
      },
    ],
  },
  {
    id: 'OIL-COCOUNT',
    category: 'oil',
    title:
      'Wood Pressed Coconut oil / மரச்செக்கு தேங்காய் எண்ணெய் / Cocos Nucifera / വെളിച്ചെണ്ണ / नारियल का तेल',
    thumbnailUrl: 'https://m.media-amazon.com/images/I/31q5ryLeYfL.jpg',
    price: 300,
    sellingPrice: 260,
    quantity: 1,
    unit: 'L',
    gallery: [
      {
        title: 'cover',
        url: 'https://m.media-amazon.com/images/I/31q5ryLeYfL.jpg',
      },
      {
        title: 'Other-1',
        url: 'https://m.media-amazon.com/images/I/31IeaOOgxGL.jpg',
      },
      {
        title: 'Other-2',
        url: 'https://m.media-amazon.com/images/I/31PrE8ywmVL.jpg',
      },

      {
        title: 'Other-3',
        url: 'https://m.media-amazon.com/images/I/410T2B-RQAL.jpg',
      },
    ],
  },
];
