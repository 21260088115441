import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { en } from 'config/metadata/en';
import { ButtonIcon, Grid } from 'components/controls';

import useStyles from './style';
import ChangePassword from './changepassw';
import Logo from './logo';
import TopMenus from '../topmenus';
import TopToolBar from './toptoolbar';

const AppBarLayout = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [handleOpen, setHandleOpen] = props.handle;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [openPasswModal, setOpenPasswModal] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const userLogout = () => {
    //Auth.logOut();
    history.push('/login');
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Login</MenuItem>
      <MenuItem onClick={handleMenuClose}>New User</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <ButtonIcon iconName="User" tooltipText="User" />

        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: handleOpen,
        })}
      >
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
            <Logo />
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
            <Grid container direction="column">
              <Grid item>
                <TopToolBar />
              </Grid>
              <Grid item>
                <div className={classes.menu}>
                  <TopMenus />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <ChangePassword
        openPasswModal={openPasswModal}
        setOpenPasswModal={setOpenPasswModal}
      />
    </div>
  );
};
AppBarLayout.propTypes = {
  handle: PropTypes.array,
};
export default AppBarLayout;
