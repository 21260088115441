import MuiButton from '@mui/material/Button';

/*
const useStyles = makeStyles((theme) => ({
    label: {
        textTransform: 'none'
    }
}))
*/

const Button = (props) => {
  const { label, onClick, color, ...rest } = props;
  return (
    <MuiButton
      key={label}
      type="submit"
      variant="contained"
      color={color ? color : 'primary'}
      onClick={onClick}
      // classes={{label: classes.label}}
      {...rest}
    >
      {label}
    </MuiButton>
  );
};
export default Button;

/*******************************
 * Example
        <Button
            label={label}          
            
          />
 */
