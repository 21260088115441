import Login from 'views/login';
import ForgetPassword from 'views/forgetpassw';
import ConfirmPassword from 'views/confirmpassw';

import Dashboard from 'views/dashboard';
import Home from 'views/home';
import Contact from 'views/contact';
import Products from 'views/products';
import ProductDetail from 'views/products/productdetail';
import CheckOut from 'views/checkout';
import ConfirmOrder from 'views/order';
import AboutUs from 'views/aboutus';

const pathRoles = {
  login: {
    path: '/login',
  },
  forgotpassw: {
    path: '/forgotpassw',
  },
  confirmpassw: {
    path: '/confirmpassw',
  },
  contact: {
    path: '/contact',
  },
  products: {
    path: '/products',
  },
  productdetail: {
    path: '/productdetail',
  },
  checkout: {
    path: '/checkout',
  },
  confirmorder: {
    path: '/confirmorder',
  },
  main: {
    path: '/',
    roles: [
      'SuperAdmin',
      'ProductAdmin',
      'ClientAdmin',
      'Specialist',
      'Report',
    ],
  },
  dashboard: {
    path: '/dashboard',
    roles: [
      'SuperAdmin',
      'ProductAdmin',
      'ClientAdmin',
      'Specialist',
      'Report',
    ],
  },
  reporting: {
    path: '/reporting',
    roles: ['SuperAdmin', 'ProductAdmin', 'ClientAdmin', 'User', 'Report'],
  },
  aboutus: {
    path: '/aboutus',
    // roles: ['SuperAdmin', 'ProductAdmin', 'ClientAdmin', 'User', 'Report'],
  },
  admin: {
    path: '/admin',
    roles: ['SuperAdmin', 'ProductAdmin'],
  },
  default: {
    path: '/*',
    roles: ['SuperAdmin', 'ProductAdmin'],
  },
};

export const routePages = [
  {
    path: pathRoles.login.path,
    component: Login,
  },
  {
    path: pathRoles.forgotpassw.path,
    component: ForgetPassword,
  },
  {
    path: pathRoles.confirmpassw.path,
    component: ConfirmPassword,
  },
  {
    path: pathRoles.contact.path,
    component: Contact,
    exact: true,
  },
  {
    path: pathRoles.products.path,
    component: Products,
    exact: true,
  },
  {
    path: pathRoles.productdetail.path,
    component: ProductDetail,
    exact: true,
  },
  {
    path: pathRoles.checkout.path,
    component: CheckOut,
    exact: true,
  },
  {
    path: pathRoles.confirmorder.path,
    component: ConfirmOrder,
    exact: true,
  },

  {
    path: pathRoles.main.path,
    component: Home,
    //  roles: pathRoles.main.roles,
    //  isPrivateRoute: true,
    exact: true,
  },
  {
    path: pathRoles.dashboard.path,
    component: Dashboard,
    roles: pathRoles.dashboard.roles,
    isPrivateRoute: true,
    exact: true,
  },
  {
    path: pathRoles.aboutus.path,
    component: AboutUs,
    exact: true,
  },
  {
    path: pathRoles.default.path,
    component: Home,
    // roles: pathRoles.default.roles,
    // isPrivateRoute: true,
    exact: true,
  },
];
export const menuList = [];
/*

export const menuList = [
  {
    Name: 'Home',
    Path: pathRoles.main.path,
    Roles: pathRoles.main.roles,
    Icon: 'Home',
  },
  {
    Name: 'Dashboard',
    Path: pathRoles.dashboard.path,
    Roles: pathRoles.dashboard.roles,
    Icon: 'Dashboard',
  },
  {
    Name: 'Reporting',
    Path: pathRoles.reporting.path,
    Roles: pathRoles.reporting.roles,
    Icon: 'Report',
  },
  {
    Name: 'Admin',
    Path: pathRoles.admin.path,
    Roles: pathRoles.admin.roles,
    Icon: 'Admin',
  },
];


================ Route Page - Metadata ====
Metadata = {
        path: string,
        component: Component,
        roles: Array,
        isPrivateRoute: boolean,
        exact: boolean
}

*/
