import { changepassw as _changepassw } from 'api/auth';

export const changePassw = async ({ username, password, newpassword }) => {
  const options = {
    username,
    password,
    newpassword,
  };
  const { data } = await _changepassw(options);
  return data;
};
