import { ADMIN_URL, AUTH_URL, ORDER_URL } from './api-endpoints';

export const adminAPI = {
  s3PresignedURL: ADMIN_URL + '/s3/presignedurl',
};
export const authAPI = {
  LOGIN: AUTH_URL + '/login',
  CHANGE_PASSW: AUTH_URL + '/changepassw',
  FORGOT_PASSW: AUTH_URL + '/forgotpassw',
  CONFIRM_PASSW: AUTH_URL + '/confirmpassw',
  TOKEN_VERIFY: AUTH_URL + '/token/verify',
  REFRESH_TOKEN: AUTH_URL + '/token/refreshtoken',
  HEALTH_CHECK: AUTH_URL + '/healthcheck',
};

export const orderAPI = {
  CONFIRM_ORDER: `${ORDER_URL}/confirm`,
};
