import {
  Card,
  CardContent,
  CardHeader,
  Box,
  Typography,
  Divider,
  Stack,
  styled,
  Paper,
} from '@mui/material';

import { Label, Grid } from 'components/controls';

import useStyles from './style';
import { contactDetails } from './metadata';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Contact = () => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        display: 'flex',
        '& > :not(style)': {
          m: 1,
          // width: 300,
          //    height: 300,
          //    alignContent: 'center',
          //    alignItems: 'center',
          //      justifyContent: 'center'
        },
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Label
            label="Cholas Trading - Office Locations"
            variant="h5"
            //   color="primary"
            //  sx={{ fontWeight: 'bold'}}
          />
          <Divider />
        </Grid>

        {contactDetails.map((contact, index) => {
          return (
            <Grid item xs={12} sm={6} key={index}>
              <Stack direction="column">
                <Label
                  label={contact.name}
                  variant="h6"
                  color={contact.isPrimary ? 'primary' : '#24aeb3'}
                  sx={{ fontWeight: 'bold' }}
                />

                <Label
                  label={
                    <Box>
                      {' '}
                      {contact.addressLines?.map((aline) => (
                        <>
                          {aline}
                          <br />
                        </>
                      ))}{' '}
                    </Box>
                  }
                  variant="body2"
                />
                {contact.email && (
                  <Label
                    label={
                      <>
                        <Box sx={{ display: 'inline' }}>Email: </Box>
                        <Box sx={{ display: 'inline' }}>{contact.email}</Box>
                      </>
                    }
                    variant="body"
                  />
                )}
                {contact.contactNo && (
                  <Label
                    label={
                      <>
                        <Box sx={{ display: 'inline' }}>ContactNo: </Box>
                        <Box sx={{ fontWeight: 'bold', display: 'inline' }}>
                          {contact.contactNo}
                        </Box>
                      </>
                    }
                    variant="body"
                  />
                )}
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default Contact;
