import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Grid } from 'components/controls';
import Box from '@mui/material/Box';

import ProductListItem from './productlistitem';
import { productList } from '../metadata';
import useStyles from './style';

const ProductList = () => {
  const classes = useStyles();

  const [products, setProducts] = useState([]);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  useEffect(() => {
    const category = queryParams.get('c');
    const _products = productList.filter(
      (x) => !category || x.category === category
    );
    setProducts(_products);
  }, [queryParams.get('c')]);
  // overflow: 'auto'
  //
  return (
    <Box className={classes.root}>
      <Grid container spacing={1}>
        {products.length > 0 &&
          products.map((pitem, index) => (
            <Grid item key={index} xs={12} sm={12} md={4} lg={4} xl={4}>
              {
                <ProductListItem
                  id={pitem.id}
                  title={pitem.title}
                  thumbnailUrl={pitem.thumbnailUrl}
                  price={pitem.price}
                  sellingPrice={pitem.sellingPrice}
                  quantity={pitem.quantity}
                  unit={pitem.unit}
                />
              }
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default ProductList;
