import Tooltip from './tooltip';

import MuiIconButton from '@mui/material/IconButton';
import { IconList } from 'icons';

const ButtonIcon = ({ iconName, onClick, tooltipText, className, ...rest }) => (
  <Tooltip key={iconName} title={tooltipText}>
    <MuiIconButton
      key={iconName}
      onClick={onClick}
      className={className}
      {...rest}
    >
      {IconList[iconName] ? IconList[iconName] : IconList['default']}
    </MuiIconButton>
  </Tooltip>
);

export default ButtonIcon;

/*
    <ButtonIcon 
        iconName={iconName}
        onClick={onClick}
        tooltipText={tooltipText}
    />
*/
