import MuiGrid from '@mui/material/Grid';

const Grid = ({ children, ...rest }) => {
  return <MuiGrid {...rest}>{children}</MuiGrid>;
};
export default Grid;

/*******************************
 * Example for import
    <Grid>
        <Label
            name="username"
            label={en.login.username}
          />
    </Grid>
 */
