import { useState, useEffect, useContext } from 'react';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import { cartContext } from 'shared/context/cart';
import { Link } from 'react-router-dom';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const CartBar = () => {
  const { cartItems, setCartItems } = useContext(cartContext);
  const [itemCount, setItemCount] = useState(0);
  useEffect(() => {
    setItemCount(cartItems.length);
  }, [cartItems]);
  return (
    <IconButton
      aria-label="cart"
      sx={{ flexGrow: 1 }}
      component={Link}
      to="/checkout"
    >
      <StyledBadge badgeContent={cartItems.length} color="primary">
        <ShoppingCartIcon />
      </StyledBadge>
    </IconButton>
  );
};

export default CartBar;
