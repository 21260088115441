import * as api from 'api/order';

export const confirmOrderService = async ({ items, details }) => {
  const params = {
    items,
    details,
  };
  const result = await api.confirmOrder(params);
  return result.data?.orderId;
};
