import * as React from 'react';
import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

import { Grid } from 'components/controls';

const ProductListItem = ({
  id,
  title,
  thumbnailUrl,
  price,
  sellingPrice,
  quantity,
  unit,
}) => {
  return (
    <ButtonBase component={Link} to={`/productdetail?id=${id}`}>
      <Paper
        sx={{
          p: 1,
          margin: 'auto',
          maxWidth: 250,
          borderRadius: '3%',
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          '&:hover': {
            background: '#f0f0f1',
          },
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} onClick={() => console.log('Image click')}>
            <Box
              component="img"
              sx={{
                height: 250,
                width: 220,
                display: 'block',
                //maxWidth: 300,
                overflow: 'hidden',
                //width: '100%',
              }}
              src={thumbnailUrl}
              alt={title}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography
                  gutterBottom
                  sx={{ fontWeight: 'bold' }}
                  variant="subtitle2"
                  component="div"
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" component="div">
                  <Box
                    component="span"
                    sx={{ textDecoration: 'line-through', color: 'red' }}
                  >
                    {' '}
                    ₹{price / quantity}
                  </Box>
                  <Box component="span" fontWeight="bold">
                    {' '}
                    ₹{sellingPrice / quantity}
                  </Box>{' '}
                  / {unit}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </ButtonBase>
  );
};

export default ProductListItem;
