import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Grid } from 'components/controls';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { productList } from '../metadata';
import useStyles from './style';
import BasicInfo from './basicinfo';
import ProductGallery from './product-gallery';
import { Helmet } from 'react-helmet';

const ProductList = () => {
  const classes = useStyles();

  const [product, setProduct] = useState({});

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  useEffect(() => {
    const productId = queryParams.get('id');
    const _product = productList.find((x) => x.id === productId);
    console.log(_product);
    setProduct(_product);
  }, [queryParams.get('id')]);
  // overflow: 'auto'
  //
  return (
    <>
      <Box className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <BasicInfo product={product} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <ProductGallery product={product} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ProductList;
/**
 *  <Helmet htmlAttributes>‍
        <title>{product.title}</title>
        <meta property="og:image" content={product.thumbnailUrl} />
        <meta property="og:title"              content={product.title} />
    </Helmet>
    */
