import * as ActionType from './actiontypes';
const initialState = {
  username: null,
  detail: null,
  token: null,
  refreshToken: null,
  currentClient: null,
  currentBusType: null,
  currentBusSubType: null,
  clients: null,
};
const reducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case ActionType.USER_ALL:
      newState = action.value;
      break;
    case ActionType.USER_Token:
      newState.token = action.value;
      break;
    case ActionType.USER_RefreshToken:
      newState.refreshToken = action.value;
      break;
    case ActionType.USER_CurentClient:
      newState.currentClient = action.value;
      break;
    case ActionType.USER_CurentBusType:
      newState.currentBusType = action.value;
      break;
    case ActionType.USER_CurentBusSubType:
      newState.currentBusSubType = action.value;
      break;
    case ActionType.USER_Logout:
      newState = {};
    default:
      break;
  }
  return newState;
};

export default reducer;
