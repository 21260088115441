import Typography from '@mui/material/Typography';

const Label = (props) => {
  const { name, label, ...rest } = props;
  return (
    <Typography margin="normal" id={name} name={name} variant="h5" {...rest}>
      {label}
    </Typography>
  );
};
export default Label;

/*******************************
 * Example for import
 * <Label
            name="username"
            label={en.login.username}
          />
 */
