import { confirmpassw as _confirmpassw } from 'api/auth';

export const confirmpassw = async ({
  username,
  type,
  verificationcode,
  newpassword,
}) => {
  const options = {
    username,
    type,
    verificationcode,
    newpassword,
  };
  const { data } = await _confirmpassw(options);
  return data;
};
