import React, { useState, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Button,
  TextField,
} from '@mui/material';
import { changePassw } from './service';
import { useHistory } from 'react-router-dom';
import { UserSelector } from 'state/user';
import Notification from 'components/notification';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  BtnSearch: {
    left: 0,
    margin: theme.spacing(1),
  },
}));

const ChangePassword = ({ openPasswModal, setOpenPasswModal }) => {
  const classes = useStyles();
  const history = useHistory();

  const [credential, setCredential] = useState({});

  const [openNotification, setOpenNotification] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [notificationMsg, setNotificationMsg] = useState(true);

  const user = useSelector(UserSelector.getUserDetail);

  const savePassword = async (e) => {
    e.preventDefault();
    if (!credential.currentpassword) {
      let sdata = { ...credential };
      sdata['requiredCurrentPassw'] = true;
      setCredential(sdata);
      return;
    } else if (!credential.newpassword) {
      let sdata = { ...credential };
      sdata['requiredNewPassw'] = true;
      setCredential(sdata);
      return;
    }
    console.log(JSON.stringify(user, 2));
    try {
      const response = await changePassw({
        username: user.email,
        password: credential.currentpassword,
        newpassword: credential.newpassword,
      });
      setNotificationMsg('Password changed successfully');
      setIsSuccess(true);
      setOpenNotification(true);
      const timeout = setTimeout(() => {
        setCredential({});
        setOpenPasswModal(false);
      }, 4000);
    } catch (e) {
      setNotificationMsg('Password change failed');
      setIsSuccess(false);
      setOpenNotification(true);
    }
  };
  const fieldStateChange = (e) => {
    let sdata = { ...credential };
    if (e && e.target.value) {
      sdata[e.target.id] = e.target.value;
    } else if (e && sdata[e.target.id]) {
      delete sdata[e.target.id];
    }
    if (sdata.requiredCurrentPassw) delete sdata['requiredCurrentPassw'];
    if (sdata.requiredNewPassw) delete sdata['requiredNewPassw'];
    setCredential(sdata);
  };
  //{alert.open ? (<AlertMessage alert={alert} setAlert={setAlert} />) : (<></>)}
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openPasswModal}
        onClose={setOpenPasswModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPasswModal}>
          <div className={classes.modalPaper}>
            <form>
              <FormControl required component="fieldset">
                <FormLabel component="legend" className={classes.grouptitle}>
                  Change password
                </FormLabel>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  error={credential.requiredCurrentPassw ? true : false}
                  name="currentpassword"
                  label="current Password"
                  type="password"
                  id="currentpassword"
                  value={credential.currentpassword}
                  onChange={fieldStateChange}
                />
                <TextField
                  margin="normal"
                  required
                  error={credential.requiredNewPassw ? true : false}
                  fullWidth
                  name="newpassword"
                  label="New Password"
                  type="password"
                  id="newpassword"
                  value={credential.newpassword}
                  onChange={fieldStateChange}
                />

                <FormGroup className={classes.BtnGroup}>
                  <FormControlLabel
                    control={
                      <div>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={<SaveIcon />}
                          className={classes.BtnSearch}
                          onClick={savePassword}
                        >
                          Save
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                          startIcon={<CloseIcon />}
                          className={classes.BtnSearch}
                          onClick={() => {
                            setCredential({});
                            setOpenPasswModal(false);
                          }}
                        >
                          Close
                        </Button>
                      </div>
                    }
                  />
                </FormGroup>
                <Notification
                  type={isSuccess ? 'success' : 'error'}
                  message={notificationMsg}
                  openNotification={openNotification}
                  setOpenNotification={setOpenNotification}
                />
              </FormControl>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

ChangePassword.propTypes = {
  openPasswModal: PropTypes.bool,
  setOpenPasswModal: PropTypes.func,
};
export default ChangePassword;
