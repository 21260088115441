import * as ActionType from './actiontypes';
const initialState = {
  version: null,
};
const reducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case ActionType.APP_ALL:
      newState = action.value;
      break;
    case ActionType.APP_VERSION:
      newState.version = action.value;
      break;
    default:
      break;
  }
  return newState;
};

export default reducer;
