import { createStore } from 'redux';
import rootReducer from './reducer';
import { loadState, saveState } from './storagestate';
import { useSelector, useDispatch } from 'react-redux';
const configureStore = () => {
  const persistedState = loadState();
  const store = createStore(rootReducer, persistedState);

  //, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() );
  store.subscribe(() => {
    saveState(store.getState());
  });
  return store;
};
export default configureStore;
